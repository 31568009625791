<script>
import { greetings } from "../constants/greetings"
import { randomItem } from "../lib/random";

export let name;
const greeting = randomItem(greetings);
</script>

<main>
  <span class="greeting-title">{greeting.greet}</span>
  <span class="greeting-language">({greeting.lang})</span>
  <span>&nbsp;&nbsp;</span>
  <span class="greeting-title">{name}!</span>
</main>

<style>
  .greeting-title {
    color: #0080FF;
		text-transform: uppercase;
		font-size: 10vw;
		font-weight: 100;
  }

  .greeting-language {
    color: #0080FF;
    font-size: 1vw;
    font-style: italic;
  }

</style>